import "./About.css";

export default function About() {
  // const styles = getStyles();
  // const imgs = getMyImgs();

  return (
    <div id="bigAbout">
      {/* <div className="topAbout">
        <h4>
          I’m a Husband, Father, Biker, and Full Stack Software Developer from
          Austin, TX. I created this website as a place to document my journey
          as I learn new things and share them with you.
        </h4>
      </div>
      <div className="topAbout img-about">
        {imgs.map((img, index) => (
          <img key={index} src={img.src} alt={img.alt} style={styles.img} />
        ))}
      </div> */}
      <div className="topAbout">
        <h4>
          I specialize in Full Stack & Mobile JavaScript with a focus on
          React.js, React Native, Node.js & TypeScript. I have a passion and
          dedication for creating web & mobile applications that are secure,
          accessible, and efficient in order to be more inclusive of diverse
          users. Below you will find my blogs, more technologies that I use and
          some of my deployed projects.
        </h4>
      </div>
    </div>
  );
}

// function getStyles() {
//   return {
//     img: {
//       height: "280px",
//     },
//   };
// }

// function getMyImgs() {
//   return [
//     {
//       src: "https://i.imgur.com/G0WHi2G.jpg",
//       alt: "me",
//     },
//     {
//       src: "https://res.cloudinary.com/dzfcrapsk/image/upload/v1661870672/IMG_2758_vladqp.jpg",
//       alt: "me",
//     },
//     // {
//     //   src: "https://i.imgur.com/emGTo5s.jpg",
//     //   alt: "me",
//     // },
//     {
//       src: "https://i.imgur.com/f8FJ3FS.jpg",
//       alt: "me",
//     },
//   ];
// }
